<template>
  <div class="musicList">
    <ScrollBar ref='scrollbar' @refresh="refresh" @loadMore="loadMore">
      <SearchNav @getId="getId" @search="search" @visible='visible=true'></SearchNav>
      <div class='list_ontent' v-if='musiclist.length>0'>
        <div class="video_list" v-for="(item,idx) in musiclist" :key='idx' @click='getVideoInfo(item)'>
          <aside class='aside'>
            <img :src="item.watermark_file+'?vframe/jpg/offset/1/w/163/h/90'" />
          </aside>
          <div class="video_name">{{item.name}}</div>
        </div>
      </div>
      <div class='nodata' v-else>
        <NoData></NoData>
      </div>
    </ScrollBar>
    <!--侧边栏（标签筛选）-->
    <DrawerTemp :visible="visible" :data="taglist" type="4"></DrawerTemp>
  </div>
</template>
<script>
import NoData from "./components/noData"
import ScrollBar from './components/ScrollBar';
import SearchNav from "./components/SearchNav.vue";
import DrawerTemp from "./components/DrawerTemp.vue";
import Buttons from '@/layout/mixins/Buttons';
import {getLabels} from '@/api/mobile';
import {fetchVideo} from "@/api/video";
export default{
  components:{
    ScrollBar,
    SearchNav,
    DrawerTemp,
    NoData
  },
  mixins: [Buttons],
  data(){
    return{
      musiclist: [],
      visible: false,
      taglist: [],
      keyword_name: '',
      order: '',
      activeTag: [],
      page: 1,
      pageSize: 20
    }
  },
  mounted() {
    this.getTaglist();
    this.getList();
  },
  methods:{
    //获取标签列表
    async getTaglist(){
      const {data, code ,msg} = await getLabels({
        type: 4
      });
      if(code !== 0){
        this.$message.error(msg);
        return;
      }
      this.taglist = data || [];
    },
    // 获取视频列表
    async getList(){
      if(window.sessionStorage.getItem('videoTag')){
        let arr = window.sessionStorage.getItem('videoTag');
        this.activeTag =  JSON.parse(arr) || [];
      }
      const {data, code ,msg} = await fetchVideo({
        page: this.page,
        limit: this.pageSize,
        label_id: this.activeTag.join(","),
        order: this.order,
        keyword_name: this.keyword_name
      });
      if(code !== 0){
        this.$message.error(msg);
        return;
      }
      this.musiclist = data.list || [];
    },
    getId(e){
      this.order = e.value;
      this.getList();
    },
    search(val){
      this.keyword_name = val;
      this.page = 0;
      this.pageSize = 20;
      this.getList();
    },
    getVideoInfo(item){
      this.$router.push({
        path: '/mobile/videoInfo',
        query:{
          videoId: item.music_id
        }
      })
    },
    // 下拉刷新
    refresh(){
      this.page = 1;
      this.pageSize = 20;
      this.getList();
      this.$refs.scrollbar.$refs.scroller.finishPullToRefresh();
    },
    // 上拉加载
    loadMore(){
      if(this.page === 1 && this.musiclist.length < 1){
        this.$refs.scrollbar.$refs.scroller.finishInfinite(true);
        return;
      }
      this.page += 1;
      if(window.sessionStorage.getItem('videoTag')){
        let arr = window.sessionStorage.getItem('videoTag');
        this.activeTag =  JSON.parse(arr) || [];
      }
      fetchVideo({
        page: this.page,
        limit: this.pageSize,
        label_id: this.activeTag.join(","),
        order: this.order,
        keyword_name: this.keyword_name
      }).then(res=>{
        if(res.code !== 0){
          this.$message.error(res.msg);
          return;
        }
        if(res.data.list.length > 0){
          this.musiclist = this.musiclist.concat(res.data.list) || [];
          this.$refs.scrollbar.$refs.scroller.finishInfinite(false);
        }else{
          this.$refs.scrollbar.$refs.scroller.finishInfinite(true);
        }
      });
    }
  },
  beforeDestroy() {
    this.$store.dispatch("mobile/updateCurrentMusic", {
      name: "",
      music_src: "",
      music_id: "",
      duration: "",
      logo: ""
    });
    this.$store.dispatch("mobile/pause");
    this.Info = null;
    window.sessionStorage.removeItem("videoTag");
  },
}
</script>
<style lang="scss" scoped>
.musicList{
  width: 100%;
  height: 100%;
  .list_ontent{
    width: 100%;
    margin-top: 16px;
    padding: 0 22px 0 20px;
    box-sizing: border-box;
    display: grid;
    //grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    //grid-gap: 4.5px;
    //grid-auto-flow: row;
    row-gap: 16px;
    column-gap: 9px;
    .video_list{
      width: 100%;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      .aside{
        width: 100%;
        height: 90px;
        video{
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .video_name{
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51,51,51,0.75);
        line-height: 12px;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
      }
    }
  }
}
.nodata{
  flex: 1;
}
/deep/{
  .swiper-container{
    .swiper-wrapper{
      height: auto !important;
    }
  }
}
</style>
